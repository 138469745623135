<template>
  <div class="all_main_wrap">
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">
          <router-link
            class="crumbs_item"
            tag="a"
            to="/admin/PromotionManage/newCouponManage"
            >优惠券管理 /</router-link
          >
          <span class="crumbs_item crumbs_last"> 领取记录</span>
        </span>
      </div>
    </div>
    <!-- 搜索条件 -->
    <div class="search-box">
      <div class="parameter">
        <a-input class="input margin" placeholder="请输入手机号" v-model="mobile" allowClear />
        <a-select placeholder="优惠券状态（可多选）" class="input" mode="tags" allowClear v-model="state">
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option value="1">待使用</a-select-option>
          <a-select-option value="2">已使用</a-select-option>
          <a-select-option value="3">已作废</a-select-option>
          <a-select-option value="4">已过期</a-select-option>
        </a-select>
      </div>
      <div>
        <a-button type="primary" class="margin" :loading="loading" @click="onSearch" v-hasPermi="['coupon:users:query']">搜索</a-button>
        <a-button @click="grantShow = true" v-hasPermi="['coupon:info:edit']">定向发放</a-button>
      </div>
    </div>
    <!-- 优惠券详情 -->
    <div class="detail-box">
      <div class="side-l">
        <p class="title">{{couponDetail.name}}</p>
        <p class="txt">满额{{couponDetail.fullPrice}}元 立减{{couponDetail.couponPrice}}元</p>
        <p class="txt">
          使用日期：
          <span v-if="couponDetail.times == 1">长期有效</span>
          <span v-else-if="couponDetail.times == 2">{{couponDetail.scopeTime}}</span>
          <span v-else-if="couponDetail.times == 3">领取后{{couponDetail.days}}天内有效</span>
        </p>
      </div>
      <div class="side-r">
        <div class="th">
          <div class="td title">设置总数量</div>
          <div class="td title">领取数量</div>
          <div class="td title">核销数量</div>
          <div class="td title">使用率</div>
        </div>
        <div class="th">
          <div class="td">{{couponDetail.noVolumes ? couponDetail.volumes : '不限量'}}</div>
          <div class="td">{{couponDetail.drawnum}}</div>
          <div class="td">{{couponDetail.usenum}}</div>
          <div class="td">{{couponDetail.userate}}%</div>
        </div>
      </div>
    </div>
    <a-table
      class="table-template"
      :rowKey="(item,index) => index"
      :columns="columns"
      :data-source="tableData"
      :loading="loading"
      :pagination="{
        total: total,
        showTotal: (res) => `共${total}条`,
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '50', '100'],
      }" @change="onPage"
    >
      <template slot="index" slot-scope="item, row, index">
        {{ (pageNumber - 1) * pageSize + index + 1 }}
      </template>
      <!-- 获得时间 -->
      <template slot="receiverTime" slot-scope="text">
        {{ text ? text : '-' }}
      </template>
      <!-- 核销时间 -->
      <template slot="useTime" slot-scope="text">
        {{ text ? text : '-' }}
      </template>
      <!-- 优惠券状态 -->
      <template slot="state" slot-scope="text">
        <span :class="text == '已使用' ? 'state_color_green' : text == '待使用' ? '' : 'state_color_red'">{{ text }}</span>
      </template>
      <template slot="operation" slot-scope="item,row">
        <span class="blueText" v-hasPermi="['coupon:users:updatestatus']">
          <span v-if="row.state == '待使用'" @click="onDelete(row.drawId)">作废</span>
          <span v-else>-</span>
        </span>
      </template>
    </a-table>

    <a-modal
      v-model="grantShow"
      class="modal"
      width='530px'
      title="定向发放"
      :centered='false'
      :closable="true"
    >
      <div class="content">
        <div class="name-inner">
          <p class="title">{{couponDetail.name}}</p>
          <p class="txt">满额{{couponDetail.fullPrice}}元 立减{{couponDetail.couponPrice}}元</p>
        </div>
        <div class="add-inner">
          <div class="search">
            <span class="side-name">添加用户：</span>
            <a-auto-complete
              style="width: 100%"
              v-model="keyword"
              placeholder="输入姓名/手机号"
              option-label-prop="value"
              @search="onSearchRole"
              :defaultActiveFirstOption="false"
              :allowClear="true"
            >
              <a-spin
                v-if="fetching"
                class="auto-spin"
                slot="notFoundContent"
                size="small"
              />
              <template slot="dataSource">
                <a-select-option
                  @click="onSelectRole(item)"
                  v-for="(item, index) in userlist"
                  :key="index + ''"
                  :value="item.nameIdcard"
                >
                  <a-row type="flex" justify="space-between" align="middle">
                    <a-col>{{ item.nameIdcard }}</a-col>
                  </a-row>
                </a-select-option>
              </template>
            </a-auto-complete>
          </div>
          <a-button type="primary" @click="onAdd">添加</a-button>
        </div>
        <div class="table-inner">
          <p>已选择用户：</p>
          <a-table
            class="table-template"
            :rowKey="(item,index) => index"
            :columns="modalColumns"
            :data-source="selectUserList"
            :loading="loading"
            :pagination="false"
            @change="onPage"
          >
            <template slot="index" slot-scope="item, row, index">
              {{ index + 1 }}
            </template>
            <template slot="operation" slot-scope="item, row, index">
              <span class="blueText">
                <span @click="onDel(index)">删除</span>
              </span>
            </template>
          </a-table>
        </div>
      </div>
      <template slot="footer">
        <a-button @click="grantShow = false">取消</a-button>
        <a-button type="primary" @click="onGrant" :loading="loading">确认增发</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
const columns = [
  {
    title: "序号",
    align: "center",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "姓名",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "手机号",
    align: "center",
    dataIndex: "mobile",
  },
  {
    title: "优惠券编码",
    align: "center",
    dataIndex: "code",
  },
  {
    title: "获券来源",
    align: "center",
    dataIndex: "receiverSource",
  },
  {
    title: "获得时间",
    align: "center",
    dataIndex: "receiverTime",
    scopedSlots: {
      customRender: "receiverTime",
    },
  },
  {
    title: "优惠券状态",
    align: "center",
    dataIndex: "state",
    scopedSlots: {
      customRender: "state",
    },
  },
  {
    title: "核销时间",
    align: "center",
    dataIndex: "useTime",
    scopedSlots: {
      customRender: "useTime",
    },
  },
  {
    title: "订单编号",
    align: "center",
    dataIndex: "orderCode",
  },
  {
    title: "操作",
    align: "center",
    width: 70,
    scopedSlots: {
      customRender: "operation",
    },
  }
]
const modalColumns = [
  {
    title: "序号",
    align: "center",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "姓名",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "手机号",
    align: "center",
    dataIndex: "mobile",
  },
  {
    title: "操作",
    align: "center",
    scopedSlots: {
      customRender: "operation",
    },
  }
]
export default {
  // 可用组件的哈希表
  components: {HeaderBox},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      columns,
      modalColumns, // 指定发放用户表头
      total:0,
      loading: false,
      tableData:[],
      grantShow:false, // 定向发放弹窗
      selectUserList:[], // 定向发放人员列表

      mobile:'',
      state:[],
      coupon_id:0,
      pageNumber: 1,
      pageSize: 10,

      keyword:'',
      userParams: { 
        type: 1, 
        mobile: '',
        name: '',
      },
      fetching: false,
      userlist:[],

      couponDetail:{}, // 优惠券详情

      volumes:0, // 发放总数量
      drawnum:0, // 领取数量
      usenum:0, // 核销数量
      userate:0, // 使用率
    }
  },
  // 事件处理器
  methods: {
    onPage(e){
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.getList();
    },
    onSearch(){
      this.pageNumber = 1;
      this.getList();
    },

    // 废除优惠券
    onDelete(id) {
      this.$confirm({
        title: "确定将当前发放给用户的优惠券进行作废吗?",
        okText: "确认",
        onOk: () => {
          this.$ajax({
            url: "/hxclass-management/new-coupon/draw-cancel?id=" + id,
            method: "PUT",
          }).then((res) => {
            if (res.code == 200 && res.success) {
              this.getList();
            } else {
              this.$message.error(res.message);
            }
          });
        },
      });
    },

    // 查询列表
    getList() {
      this.loading = true
      this.$ajax({
        url: "/hxclass-management/new-coupon/draw-list",
        params: {
          coupon_id: this.coupon_id,
          page: this.pageNumber,
          size: this.pageSize,
          state: this.state.length ? this.state.join(",") : undefined,
          mobile: this.mobile,
        },
      }).then((res) => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.total = res.data.total;
          this.tableData = res.data.records;
        }
      });
    },

    // 查询详情
    getDetail() {
      this.$ajax({
        url: "/hxclass-management/new-coupon/receive/get",
        params: {
          coupon_id: this.coupon_id
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.couponDetail = res.data
        }
      });
    },

    onDel(index){
      this.selectUserList.splice(index,1)
    },

    onAdd(){
      if(!this.selectUser){
        this.$message.warning('请选择人员');
        return
      }
      if(this.selectUserList.length > 19){
        this.$message.warning('单次最多可发放20名人员');
        return
      }
      var userId = this.selectUser.userId
      var exists = this.selectUserList.some(item => item.userId === userId)
      if(exists){
        this.$message.warning('该人员已添加，不可重复添加');
      } else {
        this.selectUserList.push(this.selectUser)
        this.selectUser = undefined
        this.keyword = null
      }
    },

    // 优惠券增发
    onGrant(){
      if(!this.selectUserList.length){
        this.$message.warning('请添加需要增发的人员');
        return
      }
      var newUserIds = this.selectUserList.map(obj => {return obj.userId})
      this.loading = true
      this.$ajax({
        url: "/hxclass-management/coupon-manage/manage/save-draw",
        method: "POST",
        params: {
          couponId: this.coupon_id,
          userIds: newUserIds
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success(res.message);
          this.getList()
          this.getDetail()

          this.grantShow = false
          this.selectUser = undefined
          this.keyword = null
          this.selectUserList = []
          this.userlist = [];
        } else {
          this.loading = false
        }
      });
    },

    // 打印选中人员
    onSelectRole(e) {
      this.selectUser = e;
    },
    
    
    // 检索人员
    onSearchRole(val) {
      this.fetching = false;
      this.userlist = [];
      if (!val) {
        clearTimeout(this.tiemID);
        return;
      }
      this.$nextTick(function () {
        if (val !== "") {
          this.fetching = true;
          const _this = this;
          clearTimeout(this.tiemID);
          this.tiemID = setTimeout(function () {
            _this.userParams.mobile = ''
            _this.userParams.name = ''
            const numberRegex = /^\d+$/;
            const isNumber = numberRegex.test(_this.keyword);
            if (isNumber) {
              _this.userParams.mobile = _this.keyword
            } else {
              _this.userParams.name = _this.keyword
            }
            _this
              .$ajax({
                method: "get",
                url: "/hxclass-management/user/info/user/info",
                params: _this.userParams,
              })
              .then((res) => {
                if (res.code == 200 && res.success) {
                  res.data.forEach((element) => {
                    element.nameIdcard = element.name + "  " + element.mobile;
                  });
                  _this.userlist = res.data;
                  _this.fetching = false;
                }
              });
          }, 400);
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created () { 
    this.volumes = this.$route.query.volumes * 1
    this.drawnum = this.$route.query.drawnum * 1
    this.usenum =this.$route.query.usenum * 1
    this.userate = (this.usenum / this.drawnum)

    this.coupon_id = this.$route.query.couponId
  },
  // 生命周期-实例挂载后调用
  mounted () {
    this.getList()
    this.getDetail()
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.search-box{
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  .margin{
    margin-right: 20px;
  }
  .parameter{
    display: flex;
    .input{
      width: 230px;
    }
  }
}
.detail-box{
  margin-top: 20px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  align-items: center;
  .side-l{
    min-width: 300px;
    margin-right: 100px;
    .title{
      font-size: 18px;
      font-weight: bold;
      color: #333333;
    }
    .txt{
      margin-top: 23px;
      font-size: 14px;
      color: #666666;
    }
  }
  .side-r{
    flex: 1;
    border: 1px solid #cccccc;
    .th{
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #cccccc;
      .td{
        text-align: center;
        width: 25%;
        line-height: 45px;
        border-right: 1px solid #cccccc;
      }
      .title{
        color: #333333;
        font-weight: 500;
      }
      .td:last-child{
        border-right: none;
      }
    }
    .th:last-child{
      border-bottom: none;
    }
  }
}
.modal{
  .content{
    border-top: 1px solid #cccccc;
    padding-top: 10px;
    color: #333333;
    .name-inner{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title{
        font-size: 16px;
        font-weight: bold;
        color: #333333;
      }
      .txt{
        font-size: 14px;
        color: #666666;
      }
    }
    .add-inner{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 20px 0;
      .search{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        margin-right: 70px;
        .side-name{
          min-width: 80px;
        }
      }
    }
    .table-inner{
      .table-template{
        margin-top: 10px;
      }
    }
  }
  /deep/ .ant-modal-header{
    padding-top: 20px !important;
  }
  /deep/ .ant-modal-body{
    padding: 12px 24px !important;
  }
  /deep/ .ant-modal-footer{
    text-align: right !important;
  }
}
</style>
